
import { defineComponent } from "vue";
import { IonPage, IonContent, IonText, toastController } from "@ionic/vue";
import PageHeader from "@/components/partial/PageHeader.vue";
import axios from "axios";
export default defineComponent({
  name: "CampaignDetail",
  components: { IonPage, IonContent, IonText, PageHeader },
  async ionViewDidEnter() {
    await axios
      .get("campaign-detail?id=" + this.$route.params.id)
      .then(({ data }) => {
        this.campaign = data.data;
      })
      .catch(async ({ response }) => {
        const toast = await toastController.create({
          message: response.data.message,
          duration: 2000,
        });
        return toast.present();
      });
    await axios
      .get("campaign-img?id=" + this.$route.params.id)
      .then(({ data }) => {
        this.campaign.imgs = data.data;
      })
      .catch(async ({ response }) => {
        const toast = await toastController.create({
          message: response.data.message,
          duration: 2000,
        });
        return toast.present();
      });
  },
  methods: {
    goToInstagram() {
      window.open(this.campaign.link_ig, "_blank");
    },
  },
  data() {
    return {
      campaign: {} as any,
    };
  },
});
